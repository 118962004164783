//home page banner
if ($('.HeaderHeroBannerContent').length) {
    $('.HeaderHeroBanner').appendTo('.HeaderHeroBannerContent').css('display', 'block');
} else { }
//ga4 external link
jQuery(function ($) {

    var linkText = $('.login').data('desktoptext');

    if (window.innerWidth >= 768) {
        $('li[aria-label="TENA Net Login"] > a').children('span').text(linkText);
    }
    $('.login').click(function (e) {
        e.preventDefault();

        var linkUrl = $(this).attr('href');
        var linkText;

        if (window.innerWidth >= 768) { // Desktop/iPad width
            linkText = $(this).data('desktoptext');
        } else { // Mobile width
            linkText = $(this).data('mobiletext');
        }
        var protocol = window.location.protocol;
        var hostname = window.location.hostname;
        var externalUrl = /^(?:(https?|ftp):\/\/|www\.)/;

        if (!externalUrl.test(linkUrl)) {
            linkUrl = protocol + "//" + hostname + linkUrl;
        }
        // Trigger the GA4 event
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "event": "ga4_select_header_cta",
            "ga4_data": {
                "link_text": linkText,
                "link_url": linkUrl
            }
        });

        // After triggering the GA4 event, allow the navigation
        window.location.href = linkUrl;
    });
    // Function to check if a link is external   
    function isExternal(link) {
        return link.hostname && link.hostname !== window.location.hostname;
    }
    // Add a class to external links   
    $('a').click(function () {
        var textfile_name = $(this).text().trim()
            || getCustomText($(this).attr('href'))
            || $(this).attr('title')
            || "No text available";
        var linkUrl = $(this).attr('href');
        if ($(this).prop('href', linkUrl).prop('hostname').indexOf('www') > -1) {
            var linkDomain = $(this).prop('href', linkUrl).prop('hostname').replace('www.', '');
        } else {
            var linkDomain = $(this).prop('href', linkUrl).prop('hostname');
        }
        if (isExternal(this) && $(this).attr('href') != 'javascript(void)' && $(this).attr('href') != "javascript(void)") {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': "ga4_click",
                'ga4_data': {
                    "link_url": linkUrl,
                    "link_domain": linkDomain,
                    "link_text": textfile_name,
                    "outbound": 'true'
                }
            });
        }
    });
    function getCustomText(href) {
        if (!href) return null; // Ensure href exists
        href = href.toLowerCase();
        if (href.includes("facebook.com")) return "Facebook";
        if (href.includes("linkedin.com")) return "LinkedIn";
        if (href.includes("youtube.com")) return "YouTube";
        return null; // Return null if no match is found
    }
    //ga4 for country selector
    $('.geo-selector-link').click(function (event) {
        event.preventDefault();
        var languageCode = $(this).data('language');
        var catalogCode = $(this).data('catalog');
        var transformedLanguageCode = languageCode.toLowerCase();
        var transformedCatalogCode = catalogCode.toUpperCase();

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "event": "ga4_geo_selector",
            "ga4_data": {
                "website_country": transformedCatalogCode,
                "website_language": transformedLanguageCode
            }
        });

        var url = $(this).attr('href');
        setTimeout(function () {
            window.location.href = url;
        }, 200);
    });
    //GA4 for pdf file event
    $('body').on('click', 'a, button', function (event) {
        var $target = $(this);
        var url = $target.attr('href');
        if (url && url.toLowerCase().endsWith('.pdf')) {
            var file_name = url.split('/').pop();
            var link_text = $target.text().trim() || $target.attr('aria-label') || 'Unknown';
            var domain = new URL(url, window.location.origin).hostname.replace(/^www\./, '');
            var file_type;
            if ($('.ProductDetails-backgroundColor').length) {
                file_type = 'Product download';
            } else {
                file_type = 'General download';
            }
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'ga4_file_download',
                ga4_data: {
                    file_extension: 'PDF',
                    file_name: file_name,
                    link_text: link_text,
                    link_url: url,
                    link_domain: domain,
                    file_type: file_type
                }
            });
        }
    });
    //GA4 for image next to text component
    $('body').on('click', '.section-signpost-standard a, .section-signpost-standard button, .section-signpost a, .section-signpost button, .signpost-campaign-component a, .signpost-campaign-component button', function (e) {
        var $target = $(this);
        var promoHeading = $target.closest('.section-signpost-standard, .section-signpost, .signpost-campaign-component').find('h2, h3').first().text().trim() || '';
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "event": "ga4_select_promotion",
            "ga4_data": {
                "creative_name": "Promo box",
                "promotion_name": promoHeading
            }
        });
    });
    //ga4 email click
    $('a').click(function () {
        var href = $(this).attr('href');
        if (href && href.startsWith('mailto:')) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "ga4_email_click"
            });
        }
    });
    //ga4 target group filter click
    $(".Tags__Button, .tag_Menu_Button").on("click", function () {
        let menuItems = $(".dropdown__item.hide-small").not(".ProfessionalMenu");
        let clickedIndex = 0;
        let linkText = $(this).text().trim();
        let menuName = "";

        if ($(this).hasClass("Tags__Button")) {
            menuName = "Second menu";
        } else if ($(this).hasClass("tag_Menu_Button")) {
            menuName = "First menu";
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "event": "ga4_menu_click",
            "ga4_data": {
                "action_type": "Content change",
                "link_text": linkText,
                "menu_name": menuName,
                "target_group": linkText
            }
        });
    });
});
if (!$('.component-form').is(':visible')) {
    var contextPathValue = $('#context-path-value').attr('data-context-path');
    var SearchModalUrl = window.location.origin + contextPathValue + "/api/Services/SearchModal/GetQuickLinks";
    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    window.onload = function () {
        if (urlParams.has('q')) {
            if (!$("#popup").is(':visible')) {
                // Retrieve and store the search query
                window.searchQuery = urlParams.get('q');
                flagforNoresultHome = false;
                urlHitQuery = true;

                $.ajax({
                    url: SearchModalUrl,
                    type: 'POST',
                    contentType: 'text/html; charset=utf-8', // Set the correct content-type header for the request
                    dataType: 'html',
                    success: function (data) {

                        performSearch();
                        searchPerformedFlag = true;
                        $('.main-search-header-data').html(data);
                        $(".header").hide();
                        $('#focusFieldOfSearch').focus();

                        if (!searchPerformedFlag) {
                            $('body').addClass('hide-scrollbar');
                            $("#popup").show();
                            $('#overlay').fadeIn();
                            $('body').css('overflow', 'hidden');
                            $('#focusFieldOfSearch').focus();
                        }
                        $("#popupCloseButton").click(async function () {
                            await new Promise(resolve => setTimeout(resolve, 100));
                            var url = window.location.href;
                            var baseUrl = url.split('?')[0];
                            window.history.replaceState(null, null, baseUrl);
                            $('#tpwSearchResult').hide();
                            $('body').css('overflow', '');
                            $('#overlay').fadeOut();
                            $("#popup").hide();
                            $('body').removeClass('hide-scrollbar');
                            $(".newAutocompleteList li").removeClass("borerBottomForSuggested");
                            $(".cross-btn-onBar").hide().addClass("d-none");
                            $(".header").show();
                            $(".autocomplete-container").removeClass("marginTopLabel");
                            crossButtonClickBar();
                            if ($("#productSelectorSearchBtn").is(":visible")) {
                                $("#searchInputProd").val('').focus();;
                            }
                        });
                        $(".cross-btn-onBar").click(function () {
                            // $('.search-container-popup').removeClass("margin-seventin");
                            $(".my-search-box").val('').focus();
                            $(".label-quiclLinks").show();
                            $(".links-container").show();
                            $(".cross-btn-onBar").hide().addClass("d-none");
                        });
                        $(".my-search-box").on('input', function () {
                            if ($(this).val().length > 0) {
                                $(".cross-btn-onBar").show();
                            } else {
                                $(".cross-btn-onBar").hide();
                            }
                        });

                        // Set tabindex for interactive elements
                        $("#popup .popup-content button, #popup .popup-content input, #popup .popup-content a").attr("tabindex", "0");
                        $(".links-container span").attr("tabindex", "0");


                        // Handle keyboard interactions for buttons and links
                        $("#popup").on("keydown", "button, a", function (event) {
                            if (event.key === "Enter" || event.key === " ") {
                                $(this).click();
                            }
                        });
                        //applyGridViewWidth();
                    },
                    error: function (xhr, status, error) {
                        console.error("Error fetching quick links:", error);
                    },
                    complete: function () {
                        // Clean up
                        clickedElementId = null;
                        searchPerformedFlag = false;
                        urlHitQuery = false;
                    }

                });
            }
        }
    };
    var resultURl = window.location.origin + contextPathValue + "/api/Services/Search/TpwSearch";

    function applyGridViewWidth() {
        // Get the width of the parent element
        let upcomingElement = document.querySelector('.col-12.gridViewActive');

        if (upcomingElement) {
            let upcomingWidth = upcomingElement.offsetWidth;

            // Only update if the new width is greater than 0
            if (upcomingWidth > 0) {
                window.gridViewActiveWidth = upcomingWidth;
            }

            // Apply the width to all search-result-content elements
            let searchResultContents = document.querySelectorAll('.col-8.col-sm-12.search-result-content');
            searchResultContents.forEach((element) => {
                element.style.width = `${window.gridViewActiveWidth}px`;
            });

            let productBoxes = document.querySelectorAll('.tpwProductBox.tpwProductCompact');
            productBoxes.forEach((box) => {
                box.style.width = `${window.gridViewActiveWidth}px`;
            });
        } else {
            console.error('Element with class .col-12.gridViewActive not found');
        }
    }

    function crossButtonClickBar() {
        $('.homepageResult').addClass('noResultHomepage');
        flagforNoresultHome = false;
        $(".label-quiclLinks").removeClass("custom-padding-quickLinks");
        $('#tpwSearchResult').hide();
        if ($("#popup").is(":visible")) {
            $("#focusFieldOfSearch").val('').focus();
        } else {
            $(".my-search-box").val('').focus();
        }

        $(".cross-btn-onBar").addClass("d-none");
        $(".autocomplete-container").removeClass("marginTopLabel");
        $(".autocomplete-label").hide();
        $("#newAutocompleteList").hide();
        $(".newAutocompleteList").hide();
        $(".discover-section").show();
        $(".new-search").addClass("footer-for-searchBar");
    }
    var flagForAllItemsClicked = false;
    var flagForProductItemsClicked = true;
    $('.allSearchItems a').on('click', function (event) {

        // Check if the clicked element is '.allSearchItems' or 'a' inside '.allSearchItems'
        if ($(event.target).is('.allSearchItems')) {

            event.preventDefault(); // Stop default behavior
            event.stopPropagation(); // Stop event from bubbling up
        } else if ($(event.target).is('.allSearchItems a')) {
            console.log('Clicked on .allSearchItems a');
        }
        flagForProductItemsClicked = true;
        if (flagForAllItemsClicked) {

            $('.component-pagination').hide();
            $('#divForLableForSearch').hide();
            $('.ProductDetailCard').html("");

            event.preventDefault();
            var url = $(this).attr('href');
            if (typeof url !== 'undefined') {
                window.currentURL = url;
            }
            url = window.currentURL;
            var queryString = getQueryString(url);
            updateQueryStringParam('q', queryString);
            $.ajax({
                url: url,
                type: 'GET',
                success: function (data) {
                    $('#divForLableForSearch').hide();
                    $('#tpwSearchData').html(data.replace('>«<', '><').replace('>»<', '><'));
                    $('#tpwSearchData').hide();
                    if (typeof window.HMSPowerReviews === 'function') {
                        new window.HMSPowerReviews();
                    } else {
                        console.error("Power Reviews initialization function not found.");
                    }

                    let searchResultContents = document.querySelectorAll('.col-8.col-sm-12.search-result-content');
                    searchResultContents.forEach((element) => {
                        element.style.width = `${window.gridViewActiveWidth}px`;
                    });
                    let productBoxes = document.querySelectorAll('.tpwProductBox.tpwProductCompact');
                    productBoxes.forEach((box) => {
                        box.style.width = `${window.gridViewActiveWidth}px`;
                    });
                    flagForAllItemsClicked = false;
                    setTimeout(() => {
                        $('#tpwSearchData').show(); // Show the content after 200ms
                    }, 200);
                },
                error: function (xhr, status, error) {
                    console.error("Error:", error);
                },
                complete: function () { }
            });
        } else {
            event.preventDefault();
            return;
        }
    });
    $('.productli a').on('click', function (event) {

        event.preventDefault();
        // Check if the clicked element is '.productli' or 'a' inside '.productli'
        if ($(event.target).is('.productli')) {

            event.preventDefault(); // Stop default behavior
            event.stopPropagation(); // Stop event from bubbling up
        } else if ($(event.target).is('.productli a') && (flagForProductItemsClicked)) {
            $('.component-pagination').hide();
            $('#divForLableForSearch').hide();
            $('.ProductDetailCard').html("");

        }
        flagForAllItemsClicked = true;

        if (flagForProductItemsClicked) {

            $('.component-pagination').hide();
            $('#divForLableForSearch').hide();
            $('.ProductDetailCard').html("");


            // Check if an AJAX call is already in progress
            if (isLoading) return;
            isLoading = true; // Set the flag to true when the AJAX call starts
            var url = $(this).attr('href');

            if (typeof url !== 'undefined') {
                window.currentURLProduct = url;
            }
            url = window.currentURLProduct;
            var queryString = getQueryString(url);
            updateQueryStringParam('q', queryString);
            $.ajax({
                url: url,
                type: 'GET',
                success: async function (data) {
                    // Insert the new data
                    $('#tpwSearchData').html(data.replace('>«<', '><').replace('>»<', '><'));


                    heightCalculateForHeading();
                    heightCalculateForParadiv();

                    $('#tpwSearchData').hide();

                    // Initialize Power Reviews if available
                    if (typeof window.HMSPowerReviews === 'function') {
                        new window.HMSPowerReviews();
                    } else {
                        console.error("Power Reviews initialization function not found.");
                    }
                    if (!$(".pagination-container").is(':visible')) {
                        $('.cardwrapper.productList.equal-card').css('padding-bottom', '64px');
                    } else {
                        $('.cardwrapper.productList.equal-card').css('padding-bottom', '0');
                    }
                    flagForProductItemsClicked = false;

                    setTimeout(() => {
                        $('#tpwSearchData').show(); // Show the content after 200ms
                    }, 200);

                },
                error: function (xhr, status, error) {
                    console.error("Error:", error);
                },
                complete: function () {
                    isLoading = false; // Reset the flag when the AJAX call completes
                    //url = null; // Clear the URL variable after completion
                }
            });
        } else {
            event.preventDefault();
            return;
        }
    });

    function performSearch() {
        $('#tpwSearchResult').removeClass('productCardView');
        var searchQuery = window.searchQuery || $('.my-search-box').val();
        //updateQueryStringParam('q', searchQuery);
        $.ajax({
            url: resultURl,
            type: 'POST',
            data: {
                query: searchQuery.split(/[&%]/)[0]
            },
            success: function (data) {
                $(".label-quiclLinks").hide();
                $(".links-container").hide();
                $(".autocomplete-label").hide();
                $("#newAutocompleteList").hide();
                $('#divForLableForSearch').hide();
                $('#tpwSearchResult').html(data.replace('>«<', '><').replace('>»<', '><'));

                if (data.includes('<div class="section-description none-results-search-page">')) {

                    $('#tpwSearchResult').find('#SearchedWord').html('"' + searchQuery + '"');

                    if ($("#popup").is(":visible")) {
                        $(".label-quiclLinks").show();
                        $(".links-container").show();
                        $(".label-quiclLinks").addClass("custom-padding-quickLinks");
                    } else {
                        $(".discover-section").hide();
                        $(".header").show();
                        $('.textContentNoresult').find('#SearchedWord').html('"' + searchQuery + '"')
                        flagforNoresultHome = true;
                        $('.homepageResult').removeClass('noResultHomepage');
                    }
                } else {
                    $(".label-quiclLinks").removeClass("custom-padding-quickLinks");
                }

                if (searchQuery) {
                    // Split the search query at the first occurrence of either '&' or '%'
                    let partBeforeSpecialChars = searchQuery.split(/[&%]/)[0]; // Get the part before '&' or '%'

                    // Now decode the URL-encoded string and replace '+' with spaces
                    let cleanedSearchQuery = decodeURIComponent(partBeforeSpecialChars).replace(/\+/g, ' ');

                    // Set the cleaned value to the input field
                    $('#focusFieldOfSearch').val(cleanedSearchQuery.trim());

                    // Show the cross button
                    $(".cross-btn-onBar").removeClass("d-none");
                }

                $(document).ready(function () {
                    if (typeof window.HMSPowerReviews === 'function') {
                        new window.HMSPowerReviews();
                    } else {
                        var scriptPath = window.location.origin + window.location.pathname.split('/')[1] + '/assets/scripts/new-tpw-jquery/PowerReviews.js';
                        var script = document.createElement('script');
                        script.src = scriptPath;

                        script.onload = function () {
                            if (typeof window.HMSPowerReviews === 'function') {
                                new window.HMSPowerReviews();
                            } else {
                                console.log("Power Reviews initialization function not found even after script load.");
                            }
                        };
                    }
                });
                $("#tpwSearchResult").show();
                if ($(".col-12.gridViewActive").is(":visible")) {
                    applyGridViewWidth();
                }
                return;
            },

            error: function (xhr, status, error) {
                console.error("Error:", error);
            },
            complete: function () {
                searchQuery = null;
                console.log(flagforNoresultHome);
                if (!flagforNoresultHome) {
                    $('body').addClass('hide-scrollbar');
                    $("#popup").show();
                    $('#overlay').fadeIn();
                    $('body').css('overflow', 'hidden');
                    if ($(".col-12.gridViewActive").is(":visible")) {
                        applyGridViewWidth();
                    }

                    $('#focusFieldOfSearch').focus();

                    var queryString = decodeURIComponent(window.location.search);
                    var urlParams = new URLSearchParams(queryString);
                    if (urlParams.has('facet_documentType') && urlParams.get('facet_documentType') === 'product') {
                        // Use a small timeout to ensure the popup is visible and fully rendered
                        setTimeout(function () {
                            if ($('.productli a').length) {
                                $('.productli a')[0].click(); // Trigger click on the first anchor tag in .productli
                            } else {
                                console.error("Anchor tag not found.");
                            }
                        }, 500); // Adjust the delay if necessary
                    }
                }
            }
        });
        flagforNoresultHome = false;
        window.searchQuery = null;
    }
}
//breadcrumb
let breadcrumFunction = function () {
    const mediaQuery = window.matchMedia('(min-width: 1118px)');
    const $breadcrumbs = $('.breadcrumbs li');

    if (!mediaQuery.matches) {
        // For mobile view: hide all items
        $breadcrumbs.hide(); // Hide all items initially
        let lastVisible = $breadcrumbs.filter(function () {
            return $(this).find('a').length > 0;
        }).last();

        lastVisible.show();
    } else {
        // For desktop view: show all breadcrumb items
        $breadcrumbs.show(); // Show all items
    }
}

jQuery(function ($) {
    breadcrumFunction();
});

$(window).on('resize', function () {
    breadcrumFunction();
    checkScreenOrientation();
});

jQuery(function ($) {
    checkScreenOrientation();
    //for cards if all childre card hidden then hide parent
    let hideParentsIfAllChildrenHidden = function () {
        setTimeout(function () {
            $('.inFocus .contentDynamic').each(function () {
                var $contentDynamic = $(this);

                var $cards = $contentDynamic.find('.inContentDynamic--SingleCard');

                if ($cards.length > 0) {
                    var allCardsHidden = $cards.filter(function () {
                        return $(this).css('display') === 'none';
                    }).length === $cards.length;

                    if (allCardsHidden) {
                        $contentDynamic.addClass('d-none');
                    } else {
                        $contentDynamic.removeClass('d-none');
                    }
                } else {
                    $contentDynamic.removeClass('d-none');
                }
            });
        }, 100);
    };

    $('.Tags__Button').on('click', function () {
        hideParentsIfAllChildrenHidden();
    });
    hideParentsIfAllChildrenHidden();

    if ($('.breadcrumbs').children().length === 1) {
        $('.breadcrumb-wrapper').css('display', 'none');
    } else {
        $('.breadcrumb-wrapper').css('display', 'block');
    }
    //In mobile if full width component needed then add padding 0 t container

    if (window.matchMedia('(max-width: 1118px)').matches) {

        $('.container#productGalleryRedesign').css({
            'padding': '0'
        });
        $('.productTableBg').find('.container').css({
            'padding': '0'
        })
    }

    if (window.matchMedia('(max-width: 576px) and (orientation: portrait)').matches) {
        // Select the table within the .richtext-content
        const table = document.querySelector('.richtext-content table');
        if (table) {
            // Create the wrapper div
            const wrapperDiv = document.createElement('div');
            wrapperDiv.classList.add('Table--horizontal-scroll');

            // Insert the wrapper before the table and append the table to the wrapper
            table.parentNode.insertBefore(wrapperDiv, table);
            wrapperDiv.appendChild(table);
            //removing widthauto in mobile due to table scroll
            $(table).parents('.width730').parents('.widthAuto').removeClass('widthAuto')
        } else {
            return false;
        }
    }
});


function checkScreenOrientation() {
    if (window.matchMedia('(max-width: 576px) and (orientation: portrait)').matches) {
        $('.campaign-section.paddingLR, .manualContentSection.paddingLR , .DynamicContentSection.paddingLR ').removeClass('paddingLR');
        $('.campaign-section, .manualContentSection, .DynamicContentSection ').parents('.section').children('.container').css('padding', '0');
    } else if (window.matchMedia('(max-width: 736px) and (orientation: landscape)').matches) {

        $('.campaign-section, .manualContentSection, .DynamicContentSection ').parents('.section').children('.container').css('padding', '0 20px ');
    }
}
$('.section.vspace-sm, .component ').each(function () {
    var section = $(this);
    var bgClasses = ['bg_bluelight', 'bg_greylight', 'bg_white'];
    bgClasses.forEach(function (bgClass) {
        if (section.find('.row').find('.' + bgClass).length > 0) {
            section.addClass(bgClass);
        }
    });
});

document.addEventListener('DOMContentLoaded', function () {
    function applyStyles() {
        const cardsCampaign = Array.from(document.querySelectorAll(
            '.main_content .campaign-component, ' +
            '.main_content .campaign-component[class~="campaign-center"], ' +
            '.main_content .campaign-component-bgImg, ' +
            '.main_content .campaign-component-rightText, ' +
            '.main_content .CampaignContainer--Media, ' +
            '.main_content .campaign-component-vdocenter'
        ))
            .filter(cardsCampaign => !cardsCampaign.closest('.bg_campaign'));

        cardsCampaign.forEach(function (cardsCampaign) {
            const cardHeight = cardsCampaign.offsetHeight;
            const isCenter = cardsCampaign.classList.contains('campaign-center');
            if (isCenter || cardsCampaign.classList.contains('campaign-component-bgImg') ||
                cardsCampaign.classList.contains('campaign-component-rightText') ||
                cardsCampaign.classList.contains('campaign-component-vdocenter')) {
                if (cardHeight > 610) {
                    cardsCampaign.style.setProperty('padding', '48px 24px', 'important');
                } else {
                    cardsCampaign.style.setProperty('padding', '96px 24px', 'important');
                }
            }
        });
    }

    if (window.matchMedia('(max-width: 567px)').matches) {
        applyStyles();
    }
    window.addEventListener('resize', function () {
        if (window.matchMedia('(max-width: 567px)').matches) {
            applyStyles();
        }
    });
});

function adjustImageWidth(selector) {
    var deviceWidth = $('.bg_campaign .campaign-component ').width();  // Get the current device width
    $(selector).each(function () {
        $(this).css("width", deviceWidth);
    });
}
$(document).ready(function () {
    if (window.matchMedia('(max-width: 1118px)').matches) {
        adjustImageWidth(".bg_campaign .CampaignContainer--Media img");

    }
});
$(window).resize(function (selector) {
    if (window.matchMedia('(max-width: 1118px)').matches) {
        adjustImageWidth(".bg_campaign .CampaignContainer--Media img");
    }
    else {
        $('.bg_campaign .CampaignContainer--Media img').each(function () {
            $(this).css("width", '100%');
        });
    }
});
//Campaign card image height equal to card 
window.addEventListener('load', updateImageHeight);
window.addEventListener('resize', updateImageHeight);

function updateImageHeight() {
    if (window.innerWidth > 768) {
        const cardcampaignHeight = document.querySelector('.campaign-section.bg_campaign');
        if (cardcampaignHeight) {
            const img = cardcampaignHeight.querySelector('.campaign-section.bg_campaign img');

            const cardHeight = cardcampaignHeight.offsetHeight;
            img.style.height = cardHeight + 'px';
        }

    }
}